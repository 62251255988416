import { enableProdMode, Injector } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular';
import packageJson from 'package.json';

import { AppModule } from './app/app.module';
import { IdentityService } from './app/services/identity.service';
import { environment } from './environments/environment';

const initializeSentry = (injector: Injector) => {
  const sentryDsn: string | null =
    'https://a7cb653bfa6fb6e88fbe917e3f1fa41b@o4507257305759744.ingest.us.sentry.io/4507258425245697';
  const envSentry: string = environment.name || 'DEV';

  if (!sentryDsn || ['LOCAL', 'DEV'].includes(envSentry)) return;

  Sentry.init({
    dsn: sentryDsn,
    release: `${envSentry}@${packageJson.version}`,
    environment: envSentry,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
        networkDetailAllowUrls: [window.location.origin, environment.apiUrl],
      }),
      Sentry.replayCanvasIntegration(),
    ],
    beforeSend(event) {
      const identityService = injector.get(IdentityService);
      const decodedUser = identityService.getDecodedToken();

      if (decodedUser !== 'invalid') {
        event.user = { id: decodedUser.id_user, ...decodedUser };
      }

      return event;
    },
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
};

if (environment.name === 'PROD') enableProdMode();

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then(moduleRef => {
    const injector = moduleRef.injector;
    initializeSentry(injector);
  });
