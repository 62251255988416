<div
  class="col-12 request-background"
  *ngFor="let request of requests; let first = first"
>
  <div class="request-container">
    <div class="request-item" [ngClass]="request.status.severity">
      <div class="request-header">
        <span class="request-title">{{ request.title }}</span>
        <span class="status">{{ request.status.name }}</span>
      </div>
      <div class="request-details">
        <p><strong>DNI solicitante:</strong> 31.424.459</p>
        <div class="request-dates">
          <p><strong>Fecha de carga: </strong>{{ request.created_at }}</p>
          <p><strong>Ultima actualización: </strong>{{ request.updated_at }}</p>
        </div>
      </div>
      <div class="request-actions">
        <p-button
          icon="pi pi-eye"
          label="Ver"
          [rounded]="true"
          [text]="true"
          size="small"
          (onClick)="onClickRequest(request)"
        />
        <p-button
          *ngIf="
            !['finish', 'rejected'].includes(request.last_node) &&
            hasRole(request.assigned)
          "
          icon="pi pi-pencil"
          label="Gestionar"
          [rounded]="true"
          [text]="true"
          size="small"
          (onClick)="onClickRequest(request)"
        />
      </div>
    </div>
  </div>
</div>
