<h1 mat-dialog-title>{{ data.title }}</h1>
<div mat-dialog-content>
  <ng-container
    *ngIf="data.url && !data.url.endsWith('storage/'); else checkPaymentType"
  >
    <img src="{{ data.url }}" width="500" />
  </ng-container>
  <ng-template #checkPaymentType>
    <ng-container *ngIf="data.payment_type === 'Efectivo'; else noImage">
      <p>Sin imagen por tratarse de un pago en efectivo</p>
    </ng-container>
  </ng-template>
  <ng-template #noImage>
    <p>El comprobante no tiene una imagen asociada.</p>
  </ng-template>
</div>
<div mat-dialog-actions align="center">
  <button
    mat-flat-button
    *ngIf="data.printable"
    color="primary"
    (click)="print()"
  >
    <mat-icon>print</mat-icon> Imprimir
  </button>
  <button mat-stroked-button mat-dialog-close>Cerrar</button>
</div>
