import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FileSelectEvent } from 'primeng/fileupload';

@Component({
  selector: 'file-button',
  templateUrl: './file-button.component.html',
  styleUrls: ['./file-button.component.scss'],
})
export class FileButtonComponent implements OnInit {
  @Input() text: string = '';
  @Input() disableButton: boolean = false;

  @Output() fileChanged: EventEmitter<{ base64: any; type: string }> =
    new EventEmitter();

  fileName: string;

  constructor() {}

  ngOnInit(): void {}

  onChangeInputFile(event: FileSelectEvent) {
    const file = event.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      this.fileChanged.emit({ base64: reader.result, type: file.type });
    };
  }
}
