<div style="display: flex; margin-bottom: 20px">
  <p-fileUpload
    mode="basic"
    [chooseLabel]="text"
    chooseIcon="pi pi-upload"
    maxFileSize="1000000"
    [disabled]="disableButton"
    (onSelect)="onChangeInputFile($event)"
  />
</div>
