<div class="base-view" *ngIf="dataLoaded">
  <section-header
    title="Solicitud de cambios de {{ requestData.person_name }}"
  ></section-header>

  <mat-card class="mt-3">
    <h2 class="ml-3">Datos actuales del usuario</h2>
    <div>
      <mat-form-field
        *ngIf="newInformation.firstname"
        class="mat-form-field-fluid"
        appearance="outline"
      >
        <mat-label>Nombre</mat-label>
        <input
          matInput
          type="text"
          [value]="originalInformation.firstname"
          readonly
        />
      </mat-form-field>
      <mat-form-field
        *ngIf="newInformation.lastname"
        class="mat-form-field-fluid"
        appearance="outline"
      >
        <mat-label>Apellido</mat-label>
        <input
          matInput
          type="text"
          [value]="originalInformation.lastname"
          readonly
        />
      </mat-form-field>
      <mat-form-field
        *ngIf="newInformation.identity_number"
        class="mat-form-field-fluid"
        appearance="outline"
      >
        <mat-label>DNI</mat-label>
        <input
          matInput
          type="text"
          [value]="originalInformation.identity_number"
          readonly
        />
      </mat-form-field>
    </div>
    <section
      class="user-images-container"
      *ngIf="
        originalInformation.photo ||
        originalInformation.identity_card_front ||
        originalInformation.identity_card_back
      "
    >
      <div>
        <div class="user-images-content">
          <span *ngIf="originalInformation.photo" class="user-images-border">
            <h4 class="user-images-title">Foto</h4>
            <img class="user-images" [src]="originalInformation.photo" />
          </span>
          <span
            *ngIf="originalInformation.identity_card_front"
            class="user-images-border"
          >
            <h4 class="user-images-title">DNI Frente</h4>
            <img
              class="user-images"
              [src]="originalInformation.identity_card_front"
            />
          </span>
          <span
            *ngIf="originalInformation.identity_card_back"
            class="user-images-border"
          >
            <h4 class="user-images-title">DNI Dorso</h4>
            <img
              class="user-images"
              [src]="originalInformation.identity_card_back"
            />
          </span>
        </div>
      </div>
    </section>
  </mat-card>

  <mat-card class="mt-3">
    <h2 class="ml-3">Cambios solicitados a aplicar</h2>
    <div>
      <mat-form-field
        *ngIf="newInformation.firstname"
        class="mat-form-field-fluid"
        appearance="outline"
      >
        <mat-label>Nombre</mat-label>
        <input
          matInput
          type="text"
          [value]="newInformation.firstname"
          readonly
        />
      </mat-form-field>
      <mat-form-field
        *ngIf="newInformation.lastname"
        class="mat-form-field-fluid"
        appearance="outline"
      >
        <mat-label>Apellido</mat-label>
        <input
          matInput
          type="text"
          [value]="newInformation.lastname"
          readonly
        />
      </mat-form-field>
      <mat-form-field
        *ngIf="newInformation.identity_number"
        class="mat-form-field-fluid"
        appearance="outline"
      >
        <mat-label>DNI</mat-label>
        <input
          matInput
          type="text"
          [value]="newInformation.identity_number"
          readonly
        />
      </mat-form-field>
    </div>
    <section
      class="user-images-container"
      *ngIf="
        newInformation.photo ||
        newInformation.identity_card_front ||
        newInformation.identity_card_back
      "
    >
      <div>
        <div class="user-images-content">
          <span *ngIf="newInformation.photo" class="user-images-border">
            <h4 class="user-images-title">Foto</h4>
            <img class="user-images" [src]="newInformation.photo" />
          </span>
          <span
            *ngIf="newInformation.identity_card_front"
            class="user-images-border"
          >
            <h4 class="user-images-title">DNI Frente</h4>
            <img
              class="user-images"
              [src]="newInformation.identity_card_front"
            />
          </span>
          <span
            *ngIf="newInformation.identity_card_back"
            class="user-images-border"
          >
            <h4 class="user-images-title">DNI Dorso</h4>
            <img
              class="user-images"
              [src]="newInformation.identity_card_back"
            />
          </span>
        </div>
      </div>
    </section>
  </mat-card>

  <mat-card class="mt-3 p-3">
    <div>
      <div class="container-send-request">
        <button mat-raised-button color="warn" (click)="sendRequest(false)">
          Rechazar
        </button>
        <button mat-raised-button color="primary" (click)="sendRequest(true)">
          Aceptar
        </button>
      </div>
    </div>
  </mat-card>
</div>
