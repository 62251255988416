import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IdentityService } from 'src/app/services/identity.service';

@Component({
  selector: 'requests-list-table',
  templateUrl: './requests-list-table.component.html',
  styleUrls: ['./requests-list-table.component.scss'],
})
export class RequestsListTableComponent implements OnInit {
  @Input() requests: any[] = [];
  @Output() onClick = new EventEmitter<any>();

  AllUserProfiles: number[] = [];
  personLoggedId: number | undefined;

  constructor(public identity: IdentityService) {
    this.identity.getLoggedUser.subscribe((loggedUser: any) => {
      if (!loggedUser || loggedUser === 'invalid') return;
      this.personLoggedId = loggedUser.id;
      this.AllUserProfiles = [
        loggedUser.id_profile,
        ...loggedUser.extra_profiles,
      ] as number[];
    });
  }

  ngOnInit() {}

  onClickRequest(request: any) {
    this.onClick.emit(request);
  }

  hasRole(role: number[]): boolean {
    // return this.AllUserProfiles.some((r) => role.includes(r));
    return true;
  }
}
