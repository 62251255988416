<div class="base-view items-center">
  <div class="avoidFrame">
    <h1>{{ title }}</h1>
    <mat-card class="p-4">
      <form [formGroup]="licenseForm" novalidate (ngSubmit)="finish()">
        <div class="p-fluid p-grid form-container">AAAAAAAAAAAAA</div>
        <br />
        <br />
        <br />
        <div class="licenses-buttons">
          <back-button></back-button>
          <button
            mat-flat-button
            class="button-save"
            color="primary"
            type="submit"
          >
            Enviar Solicitud
          </button>
        </div>
      </form>
    </mat-card>
  </div>
</div>
