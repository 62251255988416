export enum CustomTableColumnType {
  TEXT,
  BUTTONS,
  BOOLEAN_SEMAPHORE,
  HEALTH_SEMAPHORE,
  SEMAPHORE,
  DATE,
  BOOLEAN,
  NUMBER,
}
