import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { map, scan, Subject } from 'rxjs';

import { ProgressSpinnerComponent } from '../components/progress-spinner/progress-spinner.component';

@Injectable({
  providedIn: 'root',
})
export class UserInterfaceService {
  private spinnerRef: OverlayRef = this.cdkSpinnerCreate();

  spin$: Subject<boolean> = new Subject();

  constructor(private overlay: Overlay) {
    this.spin$
      .asObservable()
      .pipe(
        map(val => (val ? 1 : -1)),
        scan((acc, one) => (acc + one >= 0 ? acc + one : 0), 0),
      )
      .subscribe(res => {
        if (res === 1) {
          this.showSpinner();
        } else if (res == 0) {
          this.spinnerRef.hasAttached() ? this.stopSpinner() : null;
        }
      });
  }

  private cdkSpinnerCreate() {
    return this.overlay.create({
      hasBackdrop: true,
      backdropClass: 'bg-surface-900/40',
      positionStrategy: this.overlay
        .position()
        .global()
        .centerHorizontally()
        .centerVertically(),
    });
  }

  showSpinner() {
    this.spinnerRef.attach(new ComponentPortal(ProgressSpinnerComponent));
  }

  stopSpinner() {
    this.spinnerRef.detach();
  }
}
