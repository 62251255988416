import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ImageDialogComponent } from 'src/app/components/image-dialog/image-dialog.component';
import { StudentFileDialogComponent } from 'src/app/components/student-file-dialog/student-file-dialog.component';
import { ReceiptStatusEnum } from 'src/app/enums/receipt-status-enum';
import { FeesPaymentRequest } from 'src/app/models/payment-requests';
import { ReceiptDetails } from 'src/app/models/receipt-details';
import { ReceiptDetailsFees } from 'src/app/models/receipt-details-fees';
import { ReceiptStatusRequest } from 'src/app/models/receipt-status-request';
import { ReceiptStatusValues } from 'src/app/models/receipt-status-values';
import { ApiPaymentsService } from 'src/app/services/api-payments.service';
import { UserInterfaceService } from 'src/app/services/user-interface.service';

@Component({
  selector: 'app-payment-receipt-details',
  templateUrl: './payment-receipt-details.component.html',
  styleUrls: ['./payment-receipt-details.component.scss'],
})
export class PaymentReceiptDetailsComponent implements OnInit {
  receiptId: number;
  courseId: number;
  personId: number;
  title: string;
  selectedFees: string[] = [];
  enrollmentPrice: number;
  feePrice: number;

  showPaymentFees: boolean = true;
  loadFeesHistory: boolean = false;

  receipt: ReceiptDetails;
  rsRequest: ReceiptStatusRequest;

  receiptDetailsForm: UntypedFormGroup;

  statusReceipts: typeof ReceiptStatusEnum = ReceiptStatusEnum;
  statusValues: ReceiptStatusValues[] = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private APIPayment: ApiPaymentsService,
    private fb: UntypedFormBuilder,
    private imageDialog: MatDialog,
    private studentFileDialog: MatDialog,
    private ui: UserInterfaceService,
  ) {
    this.route.params.subscribe(params => {
      this.receiptId = params['receiptId'];
      this.title = 'Comprobante #' + this.receiptId;

      this.receiptDetailsForm = this.fb.group({
        status: ['1', Validators.required],
        fees: [null, Validators.required],
        comments: [null],
      });
    });
  }

  ngOnInit(): void {
    this.receipt = new ReceiptDetails();
    this.getReceiptDetails();
    this.getReceiptStatusValues();
  }

  getReceiptDetails() {
    this.ui.spin$.next(true);
    this.APIPayment.getReceiptDetails(this.receiptId).subscribe(response => {
      this.receipt = response.data;
      this.courseId = this.receipt.id_course;
      this.personId = this.receipt.id_person;
      this.enrollmentPrice = this.receipt.enrollment_price;
      this.feePrice = this.receipt.fee_price;

      if (this.receipt.image.endsWith('storage/')) {
        this.receipt.image = '';
      }

      this.receipt.fees.forEach((f: any) => {
        this.selectedFees.push(f.fee_number.toString());
      });

      this.loadFeesHistory = true;

      // this.receiptDetailsForm.get('status')?.setValue('1');
      this.ui.spin$.next(false);
    });
  }

  getReceiptStatusValues() {
    this.APIPayment.getReceiptStatusValues().subscribe(response => {
      this.statusValues = response.data;
    });
  }

  checkFeeEnrollValues() {
    const feesEnrollValues = this.receiptDetailsForm.get('fees')!.value;
    const receiptStatus = this.receiptDetailsForm.get('status')?.value;
    let valid: boolean = true;

    feesEnrollValues.forEach((fev: FeesPaymentRequest) => {
      if (fev.number == 0 && fev.amount! > this.enrollmentPrice) {
        alert('El valor asignado a la matrícula supera el costo de la misma');
        valid = false;
      } else if (fev.number != 0 && fev.amount! > this.feePrice) {
        alert(
          'El valor asignado a alguna de las cuotas supera el costo de las mismas',
        );
        valid = false;
      } else if (
        fev.number == -1 &&
        receiptStatus != ReceiptStatusEnum.REJECTED
      ) {
        alert('Debe seleccionar una cuota para el pago de la misma');
        valid = false;
      }
    });

    return valid;
  }

  sendAnswerReceipt() {
    if (this.receiptDetailsForm.valid) {
      if (this.checkFeeEnrollValues()) {
        this.ui.spin$.next(true);

        let feesRequest: FeesPaymentRequest[] = [];
        const formFees = this.receiptDetailsForm.get('fees')?.value;
        const receiptStatus = this.receiptDetailsForm.get('status')?.value;

        // VALIDO LOS FEES AGREGADOS POR EL USUARIO Y MARCO CON DELETE LOS QUE QUITE
        this.receipt.fees.forEach((userFee: ReceiptDetailsFees) => {
          const feeValues = formFees.find(
            (f: FeesPaymentRequest) => f.number == userFee.fee_number,
          );
          feesRequest.push({
            number: userFee.fee_number,
            amount: feeValues ? feeValues.amount : 0,
            id_payment_receipt: userFee.id_payment_receipt,
            delete: feeValues ? false : true,
          });
        });

        // RECORRO LOS FEES MARCADOS POR TESORERIA Y SI HAY ALGUNO NUEVO LO AGREGO
        formFees.forEach((formFee: FeesPaymentRequest) => {
          const feeValues = this.receipt.fees.find(
            (f: ReceiptDetailsFees) => f.fee_number == formFee.number,
          );

          if (!feeValues) {
            feesRequest.push({
              number: formFee.number,
              amount: formFee.amount,
              id_payment_receipt: 0,
              delete: false,
            });
          }
        });

        if (receiptStatus == ReceiptStatusEnum.REJECTED) feesRequest = [];

        this.rsRequest = {
          id_course: this.courseId,
          id_receipt: this.receiptId,
          id_receipt_status: receiptStatus,
          fees: feesRequest,
          comments: this.receiptDetailsForm.get('comments')?.value,
        };

        this.APIPayment.setReceiptStatus(this.rsRequest).subscribe(response => {
          this.ui.spin$.next(false);
          this.router.navigate(['payment-receipts-list']);
        });
      }
    } else {
      Object.keys(this.receiptDetailsForm.controls).forEach(field => {
        const control = this.receiptDetailsForm.get(field);
        control?.markAsTouched({ onlySelf: true });
      });
    }
  }

  onChangeStatus(event: any) {
    const control = this.receiptDetailsForm.controls['comments'];

    if (event == ReceiptStatusEnum.REJECTED) {
      this.showPaymentFees = false;
      control.setValidators(Validators.required);
    } else {
      this.showPaymentFees = true;
      control.clearValidators();
    }

    control.updateValueAndValidity();
  }

  showImageDialog(url: string) {
    this.imageDialog.open(ImageDialogComponent, {
      data: {
        title: 'Comprobante #' + this.receiptId,
        url: url,
        printable: true,
      },
    });
  }

  showUserInformation() {
    this.studentFileDialog.open(StudentFileDialogComponent, {
      width: '90%',
      maxWidth: '800px',
      data: { personId: this.personId },
    });
  }
}
