import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { ActivatedRoute } from '@angular/router';
import { UserProfilesEnum } from 'src/app/enums/user-profiles-enum';
import {
  AddPaymentRequest,
  FeesPaymentRequest,
} from 'src/app/models/payment-requests';
import { ApiPaymentsService } from 'src/app/services/api-payments.service';
import { IdentityService } from 'src/app/services/identity.service';
import { UserInterfaceService } from 'src/app/services/user-interface.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-add-payment-receipt',
  templateUrl: './add-payment-receipt.component.html',
  styleUrls: ['./add-payment-receipt.component.scss'],
})
export class AddPaymentReceiptComponent implements OnInit {
  courseId: number;
  personId: number;
  isStudent: boolean = true;

  receiptForm: UntypedFormGroup;
  receiptFileValid: boolean = true;
  isCash: boolean = false;
  paymentType: string = 'transfer';

  profilesValues: typeof UserProfilesEnum = UserProfilesEnum;

  constructor(
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private APIPayments: ApiPaymentsService,
    private identity: IdentityService,
    private location: Location,
    private utils: UtilsService,
    private ui: UserInterfaceService,
  ) {
    this.receiptForm = this.fb.group({
      image: [null, Validators.required],
      id_course: [null, Validators.required],
      fees: [null, Validators.required],
      transfer_date: [null, Validators.required],
      transfer_owner: [null, Validators.required],
      comments: ['', Validators.maxLength(300)],
    });
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.receiptForm.get('id_course')?.setValue(params['courseId']);
      this.courseId = params['courseId'];

      this.personId = params['personId']
        ? params['personId']
        : this.identity.getDecodedToken().id_person;

      if (!this.identity.getDecodedToken().id_person) {
        this.isStudent = false;
      }
    });
  }

  onFileChanged(event: any) {
    this.receiptFileValid = this.utils.receiptFileValidation(event.type);
    this.receiptForm.get('image')?.setValue(event.base64);
  }

  sendReceipt() {
    if (this.receiptForm.valid && this.receiptFileValid) {
      this.ui.spin$.next(true);
      const feesRequest: FeesPaymentRequest[] = [];

      this.receiptForm.get('fees')?.value.forEach((fee: any) => {
        feesRequest.push(fee);
      });

      let payment: AddPaymentRequest = new AddPaymentRequest();

      payment = {
        id_course: this.receiptForm.get('id_course')?.value,
        id_person: this.personId,
        image: this.receiptForm.get('image')?.value,
        fees: feesRequest,
        transfer_date: this.receiptForm.get('transfer_date')?.value,
        transfer_owner: this.receiptForm.get('transfer_owner')?.value,
        comments: this.receiptForm.get('comments')?.value,
        payment_type: this.isCash ? 'cash' : 'transfer',
      };

      this.APIPayments.addPayment(payment).subscribe(response => {
        this.ui.spin$.next(false);
        this.goToPreviousPage();
      });
    } else {
      Object.keys(this.receiptForm.controls).forEach(field => {
        const control = this.receiptForm.get(field);
        control?.markAsTouched({ onlySelf: true });
      });
      // let dialogElements: ElementsAlertDialog;

      // if (this.receiptForm.get('image')?.hasError('required')) {
      //   dialogElements = {
      //     title: "No se pudo realizar la operación",
      //     content: "No se agregó ningún archivo o el archivo agregado no tiene el formato correcto. Formatos permitidos: jpg, jpeg, png, pdf.",
      //     status: "danger",
      //     closeButtonLabel: "Cerrar"
      //   };

      //   this.dialog.open(AlertDialogComponent, {
      //     data: dialogElements
      //   });
      // }
    }
  }

  handleCashChange(event: MatCheckboxChange) {
    const validations = [Validators.required];

    if (event.checked) {
      this.receiptForm.get('image')?.setValue(null);
      validations.shift();
    }

    this.isCash = event.checked;
    this.receiptForm.get('image')?.setValidators(validations);
    this.receiptForm.get('image')?.updateValueAndValidity();
    this.receiptForm.get('transfer_owner')?.setValidators(validations);
    this.receiptForm.get('transfer_owner')?.updateValueAndValidity();
  }

  goToPreviousPage() {
    this.location.back();
  }
}
